
/* eslint-disable vue/no-setup-props-destructure */
/* eslint-disable vue/no-dupe-keys */

import {
  reactive,
  ref
} from 'vue'
import moment from 'moment'

export default {
  name: 'FilterDatePayout',
  props: {
    data: {
      default: () => []
    }
  },
  emits: ['submitDate'],
  setup(props: any, {
    emit
  }: any) {
    const {
      data
    } = reactive(props)
    const tempTitle = ref([]) as any
    const tempDay = ref(null)
    const tempMonth = ref(null)

    const backPosition = (num: any) => {
      tempTitle.value.splice(num, 1)
    }

    const submitDate = (val: any) => {
      emit('submitDate', val)
    }

    const nextPosition = (value: any, list: any) => {
      if (tempTitle.value.length === 0) {
        tempMonth.value = list
      } else if (tempTitle.value.length === 1) {
        tempDay.value = list
      }
      tempTitle.value.push(value)
    }
    return {
      data,
      tempTitle,
      tempDay,
      tempMonth,
      backPosition,
      nextPosition,
      moment,
      submitDate
    }
  }

}
